/* Member Summary, Vue Component */
<template>
    <v-card class="entity-view">
      <v-card-title>{{ title }}</v-card-title>

      <v-card-text class="d-flex align-center justify-space-between flex-wrap pb-0">

      <div class="fields d-flex align-center flex-wrap pb-0">
        <div>
          <h4>Reference</h4>
          <output>{{ entity.Reference }}</output>
        </div>

        <div>
          <h4>Name</h4>
          <output>{{ entity.FullName }}</output>
        </div>

        <div>
          <h4>Status</h4>
          <output><v-icon size="18" :color="booleanIcons(entity.Enabled).variant">{{ booleanIcons(entity.Enabled).icon }}</v-icon></output>
        </div>

      </div>

      <div class="fields d-flex align-center flex-wrap pb-0">

        <div>
          <h4>Telephone</h4>
          <output>{{ entity.Telephone || '&nbsp;' }}</output>
        </div>

        <div>
          <h4>Email</h4>
          <output class="d-flex align-center">{{ entity.Email }}
          <v-icon :class="entity.EmailValidated ? 'ml-0 mt-1' : 'ml-2'" :title="entity.EmailValidated ? 'Validated Email' : 'Not Validated'" size="16" :color="yesNoIcons(entity.EmailValidated).variant">{{ yesNoIcons(entity.EmailValidated).icon }}</v-icon></output>
        </div>

      </div>

      <div class="fields d-flex align-center flex-wrap pb-0">

        <div>
          <h4>Country</h4>
          <output>{{ entity.CountryId_Name || '&nbsp;'  }}</output>
        </div>

      </div>

        <div class="my-6 ml-auto d-flex justify-right">

          <slot></slot>
        </div>

      </v-card-text>

    </v-card>

</template>

<script>
  import store from '@/store'
  import { useRouter } from '@core/utils'
  import * as Display from '@/datahelpers/displayTypes'

  import {
    mdiPencilOutline,
    mdiCheckboxBlankOutline,
    mdiCheckboxMarkedOutline,
    mdiClose,
    mdiBookmarkCheck,
    mdiAccountQuestion
  } from '@mdi/js'

  import { toRefs  } from '@vue/composition-api'

  export default {
    props: {
      title: {
         type: String,
         required: true
      },
      member: {
        type: Object,
        required: true
      }
    },
    setup(props) {

      const { title, member } = toRefs(props);

      const booleanIcons = status => {
        if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
        return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
      }

      const yesNoIcons = status => {
        if (status) return { variant: 'success', icon: mdiBookmarkCheck }
        return { variant: 'warning', icon: mdiAccountQuestion }
      }

      return {
        entity: member,
        Display,
        booleanIcons,
        yesNoIcons,
        icons: {
          mdiPencilOutline,
          mdiClose
        },
      };
    }
  }
</script>

<style lang="scss" scoped>

</style>

